import { useLocation } from "react-router-dom";
import { urlType } from "src/constants/constants";

export const getNameValueFromId = (obj, id) => {
  for (const key in obj) {
    if (obj[key].id === id) {
      return obj[key].name;
    }
  }
  return "-";
};

export const countNumberFormatter = (num) => {
  return Math.abs(num) > 999999 ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(2) + "M" : Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + "K" : Math.sign(num) * Math.abs(num);
};

export const convertMarketPrices = (marketPriceObj) => {
  const result = [];

  for (const [key, values] of Object.entries(marketPriceObj)) {
    if (Array.isArray(values) && values.length > 0) {
      values.forEach((value) => {
        if (value) {
          result.push({
            marketPriceId: value.marketPriceId ?? 0,
            marketPrice: value.price ?? 0,
            marketPriceTypeId: value.marketPriceTypeId ?? 0,
            marketPriceName: value.name ?? "string"
          });
        }
      });
    }
  }

  return result;
};

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  result.forEach((category, index) => {
    category.sortOrder = (index + 1).toString();
  });
  return result;
};

export const findNewSortedArrayChanged = (parentCopyArray, newArray) => {
  const referenceMap = new Map(parentCopyArray.map((item, index) => [item.sortOrder, index]));
  const newSortedArray = [];

  newArray.forEach((item, index) => {
    const referenceIndex = referenceMap.get(item.sortOrder);
    if (referenceIndex !== undefined && referenceIndex !== index) {
      newSortedArray.push(item);
    }
  });

  return newSortedArray;
};

export const useQuery = () => new URLSearchParams(useLocation().search);

export const urlIsVideoOrImage = (url) => {
  if (!url) return null;
  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".tiff", ".svg", ".webp", ".apng", ".avif", ".svg"];
  const videoExtensions = [".mp4", ".avi", ".mov", ".wmv", ".flv", ".webm", ".mkv"];
  const parsedUrl = url.split("?")[0];

  // Extract the file extension
  const extension = parsedUrl.split(".").pop().toLowerCase();
  return imageExtensions.includes(`.${extension}`) ? urlType.image : videoExtensions.includes(`.${extension}`) ? urlType.video : null;
};

export const urlIsVideoOrImageUpload = (file) => {
  if (!file) return null;
  return file.type.startsWith("image/") ? urlType.image : file.type.startsWith("video/") ? urlType.video : null;
};

export const getBrandLabelArray = (arr) => (arr.includes(0) && arr.length > 1 ? getBrandLabelArray(arr.filter((num) => num !== 0)) : arr.includes(1) && arr.includes(2) ? [1, 2] : [arr[0]]);

export const getBrandValueArray = (value) => (value === null || value === 0 ? [0] : value === 1 ? [1] : value === 2 ? [2] : value === 3 ? [1, 2] : []);

export const processShowBrandInfo = (arr) => (arr.includes(0) && arr.length > 1 ? processShowBrandInfo(arr.filter((num) => num !== 0)) : arr.includes(1) && arr.includes(2) ? 3 : arr[0]);

export const isValidYoutubeLink = (url) => /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|shorts\/|v\/)?([a-zA-Z0-9_-]{11})(\S+)?$/.test(url);

export const extractYoutubeVideoId = (url) => {
  const regex = /(?:youtube\.com\/(?:embed\/|watch\?v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const matches = url.match(regex);
  return matches ? matches[1] : null;
};

export const transactionTypeSchema = (transactionTypeId) => {
  switch (transactionTypeId) {
    case 1:
      return { name: "Top up", colorScheme: "#FFCE00" };
    case 2:
      return { name: "Bonus", colorScheme: "#005AFF" };
    case 3:
      return { name: "Pay In", colorScheme: "#2FAA73" };
    case 4:
      return { name: "Pay Out", colorScheme: "#EC1C24" };
    case 5:
      return { name: "Referral Bonus", colorScheme: "#005AFF" };
    case 6:
      return { name: "Order", colorScheme: "#BFBFBF" };
    case 7:
      return { name: "Joining Bonus", colorScheme: "#005AFF" };
    case 8:
      return { name: "Money Transfer", colorScheme: "#e2e2e2" };
    case 9:
      return { name: "Pamoja Bonus", colorScheme: "#005AFF" };
    case 10:
      return { name: "Jipange Bonus", colorScheme: "#005AFF" };
    case 11:
      return { name: "Refund", colorScheme: "#AFE1AF" };
    default:
      return { name: "--", colorScheme: "#FFF" };
  }
};
