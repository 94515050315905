import { AppBar, Dialog, DialogContent, Grid, Toolbar } from "@mui/material";
import { useContext } from "react";
// eslint-disable-next-line
import { GlobalContext } from "src/context/GlobalState";
import { addressBox, crmDetailsText, dialogHeader, orderDetailsHeading } from "src/styles/styles";

const AddressPopUp = ({ selectedAddress, setSelectedAddress }) => {
  const {
    dispatch,
    state: { crmState }
  } = useContext(GlobalContext);
  const handleClose = () => {
    setSelectedAddress(null);
    dispatch({ type: "displayAddress", payload: false });
  };

  return (
    <Dialog
      open={crmState.displayAddress}
      onClose={handleClose}
      // style={{ width: "1500px" }}
    >
      <AppBar
        sx={{
          position: "relative",
          height: "40px",
          left: "0px",
          top: "0px",
          background: "#232F3E"
        }}
      >
        <Toolbar className="">
          <h6 style={dialogHeader}>Address and Location Details</h6>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container>
          <Grid xs={8}>
            <Grid container>
              <Grid xs={6}>
                <p style={orderDetailsHeading}>Latitude</p>
                <p style={crmDetailsText}>{selectedAddress?.latitude}</p>
              </Grid>
              <Grid xs={6}>
                <p style={orderDetailsHeading}>Longitude</p>
                <p style={crmDetailsText}>{selectedAddress?.longitude}</p>{" "}
              </Grid>
            </Grid>
            <p style={orderDetailsHeading}>Street Name</p>
            <p style={crmDetailsText}>{selectedAddress?.street}</p>
            <p style={orderDetailsHeading}>House No./Apartment Name</p>
            <p style={crmDetailsText}>{selectedAddress?.houseNumber}</p>
            <p style={orderDetailsHeading}> State/Province</p>
            <p style={crmDetailsText}>{selectedAddress?.state}</p>
            <p style={orderDetailsHeading}>City</p>
            <p style={crmDetailsText}>{selectedAddress?.city}</p>
            <p style={orderDetailsHeading}>Country</p>
            <p style={crmDetailsText}>{selectedAddress?.country}</p>
          </Grid>
          <Grid xs={4}>
            <div style={addressBox}>
              <p style={orderDetailsHeading}>Landmark/Directions</p>
              <p style={crmDetailsText}>{selectedAddress?.landMark}</p>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddressPopUp;
